// utils/formatInteger.js
const formatInteger = (amount) => {
  // Customize the formatting options as needed (e.g., currency: 'USD')
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0, // Specify no decimal places
    maximumFractionDigits: 0, // Specify no decimal places
  });
  return formatter.format(amount);
};

export default formatInteger;
