import "react-toastify/dist/ReactToastify.css";

import { Bounce, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";

import Select from "react-select";

const MARK_AS_PAID_MUTATION = gql`
  mutation ($id: String!, $status: String!) {
    invoiceMarkAsPaid(id: $id, status: $status) {
      invoice {
        id
      }
    }
  }
`;

const StatusSelect = ({ id, selectedStatus, refetch, error }) => {
  const [mark_as_paid] = useMutation(MARK_AS_PAID_MUTATION, {
    onCompleted: (data) => {
      if (data && data.invoiceMarkAsPaid) {
        refetch();
        toast.success("Status updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        console.error("Error marking invoices as paid:", error);
        toast.error("Failed to update status", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    },
    onError: (error) => {
      console.error("Error marking invoices as paid:", error);
      toast.error("An error occurred while updating status", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    },
  });

  const [statusOptions, setStatusOptions] = useState([
    { label: "Ready to Invoice", value: "Ready to Invoice" },
    { label: "Request for GR sent", value: "Request for GR sent" },
    { label: "Invoice Sent", value: "Invoice Sent" },
    { label: "Follow-up 1", value: "Follow-up 1" },
    { label: "Follow-up 2", value: "Follow-up 2" },
    { label: "Follow-up 3", value: "Follow-up 3" },
    { label: "Escalated", value: "Escalated" },
    { label: "Payment Received", value: "Payment Received" },
  ]);

  const [status, setStatus] = useState({ label: "", value: "" });

  useEffect(() => {
    if (selectedStatus) {
      setStatus({ label: selectedStatus, value: selectedStatus });
    } else {
      setStatus({ label: "", value: "" });
    }
  }, [selectedStatus]);

  const handleOnChange = (selectedOption) => {
    const { value } = selectedOption;
    setStatus(selectedOption);
    mark_as_paid({
      variables: {
        id: id,
        status: value,
      },
    });
  };

  return (
    <Select
      options={statusOptions}
      name="status"
      id="status"
      instanceId="status"
      value={{ label: status.label, value: status.value }}
      onChange={handleOnChange}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      className="whitespace-nowrap capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    />
  );
};

export default StatusSelect;
