import { Link, useLocation, useParams } from "react-router-dom";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import Header from "../../components/Header";
import { Layout } from "@components";
import moment from "moment";

const GET_PROGRAM_QUERY = gql`
  query ($id: String!) {
    program(id: $id) {
      program {
        id
        clientName
        clientDepartment
        clientContactName
        clientContactDetails
        programName
        programVenue
        programPax
        programTypeIndoorModular
        programTypeTeambuilding
        programTypeConsultation
        programTypeIndoorConvention
        programToolName
        programToolCost
        programToolPrice
        programToolQuantity
        programOverhead
        programToolContractPrice
        totalInvoicePrice
        isInvoiceEqualToContractPrice
        programTeamProfileCost
        programTeamProfileName
        programTeamProfilePrice
        programTeamProfileQuantity
        totalContractPrice
      }
    }
    allSales(programId: $id) {
      sales {
        id
        saleType {
          id
          referenceCode
          referenceType
          referenceDescription
        }
        personnel {
          id
          firstName
          lastName
        }
        salesPercentage
      }
    }
    allRosters(programId: $id) {
      rosters {
        id
        roleType {
          id
          referenceCode
          referenceType
          referenceDescription
        }
        personnel {
          id
          firstName
          lastName
        }
        rosterDate
        rate
        description
        remarks
      }
    }
    allAllowances(programId: $id) {
      allowances {
        id
        allowanceType {
          id
          referenceCode
          referenceType
          referenceDescription
        }
        personnel {
          id
          firstName
          lastName
        }
        receiveReceiptDate
        cost
      }
    }
    allAdvances(programId: $id) {
      advances {
        id
        cashAdvanceDate
        cost
        status
        advanceType {
          id
          referenceCode
          referenceType
          referenceDescription
        }
        personnel {
          id
          firstName
          lastName
        }
      }
    }
    allInvoices(programId: $id) {
      invoices {
        billedTo
        id
        invoiceDate
        price
      }
    }
  }
`;

const DELETE_SALE_MUTATION = gql`
  mutation ($id: String!) {
    deleteSale(id: $id) {
      sale {
        id
      }
    }
  }
`;

const DELETE_ROSTER_MUTATION = gql`
  mutation ($id: String!) {
    deleteRoster(id: $id) {
      roster {
        id
      }
    }
  }
`;

const DELETE_ALLOWANCE_MUTATION = gql`
  mutation ($id: String!) {
    deleteAllowance(id: $id) {
      allowance {
        id
      }
    }
  }
`;

const DELETE_ADVANCE_MUTATION = gql`
  mutation ($id: String!) {
    deleteAdvance(id: $id) {
      advance {
        id
      }
    }
  }
`;

const DELETE_INVOICE_MUTATION = gql`
  mutation ($id: String!) {
    deleteInvoice(id: $id) {
      invoice {
        id
      }
    }
  }
`;

export default function ProgramShow() {
  const [role, setRole] = useState(0);
  const { id } = useParams();
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const module = queryParam.get("module");
  const [activeTab, setActiveTab] = useState(module || "Sales Team");
  const [invoicesData, setInvoicesData] = useState([]);
  const [advancesData, setAdvancesData] = useState([]);
  const [allowancesData, setAllowancesData] = useState([]);
  const [rostersData, setRostersData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const { data } = useQuery(GET_PROGRAM_QUERY, { variables: { id } });
  const [programData, setProgramData] = useState();

  const [delete_sale] = useMutation(DELETE_SALE_MUTATION, {
    onCompleted: (data) => {
      if (data && data.deleteSale) {
        window.location.replace(`/programs/${id}?
        Sales Team`);
      } else {
        alert("Something went wrong");
      }
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });

  const [delete_roster] = useMutation(DELETE_ROSTER_MUTATION, {
    onCompleted: (data) => {
      if (data && data.deleteRoster) {
        window.location.replace(`/programs/${id}?module=Rosters`);
      } else {
        alert("Something went wrong");
      }
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });

  const [delete_allowance] = useMutation(DELETE_ALLOWANCE_MUTATION, {
    onCompleted: (data) => {
      if (data && data.deleteAllowance) {
        window.location.replace(`/programs/${id}?module=Allowances`);
      } else {
        alert("Something went wrong");
      }
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });

  const [delete_advance] = useMutation(DELETE_ADVANCE_MUTATION, {
    onCompleted: (data) => {
      if (data && data.deleteAdvance) {
        window.location.replace(`/programs/${id}?module=Advances`);
      } else {
        alert("Something went wrong");
      }
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });

  const [delete_invoice] = useMutation(DELETE_INVOICE_MUTATION, {
    onCompleted: (data) => {
      if (data && data.deleteInvoice) {
        window.location.replace(`/programs/${id}?module=Invoices`);
      } else {
        alert("Something went wrong");
      }
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });

  useEffect(() => {
    if (data) {
      const accessLevel = sessionStorage.getItem("accessLevel");
      setRole(accessLevel);

      let sales = data.allSales.sales.map((sale) => ({
        id: sale.id,
        sales_type: sale.saleType?.referenceDescription || "N/A",
        personnel: sale.personnel
          ? `${sale.personnel.firstName} ${sale.personnel.lastName}`
          : "N/A",
        sales_percentage: sale.salesPercentage,
      }));

      setSalesData(sales);

      let rosters = data.allRosters.rosters.map((roster) => ({
        id: roster.id,
        roleType: roster.roleType?.referenceDescription || "N/A",
        personnel: roster.personnel
          ? `${roster.personnel.firstName} ${roster.personnel.lastName}`
          : "N/A",
        rosterDate: roster.rosterDate,
        rate: roster.rate,
        description: roster.description,
        remarks: roster.remarks,
      }));

      setRostersData(rosters);

      let allowances = data.allAllowances.allowances.map((allowance) => ({
        id: allowance.id,
        allowanceType: allowance.allowanceType?.referenceDescription || "N/A",
        personnel: allowance.personnel
          ? `${allowance.personnel.firstName} ${allowance.personnel.lastName}`
          : "N/A",
        receiptReceiveDate: allowance.receiveReceiptDate,
        cost: allowance.cost,
      }));

      setAllowancesData(allowances);

      let advance = data.allAdvances.advances.map((advance) => ({
        id: advance.id,
        advanceType: advance.advanceType?.referenceDescription || "N/A",
        personnel: advance.personnel
          ? `${advance.personnel.firstName} ${advance.personnel.lastName}`
          : "N/A",
        cashAdvanceDate: advance.cashAdvanceDate,
        cost: advance.cost,
        status: advance.status,
      }));

      setAdvancesData(advance);

      let invoice = data.allInvoices.invoices.map((invoice) => ({
        id: invoice.id,
        billedTo: invoice.billedTo,
        invoiceDate: invoice.invoiceDate,
        price: invoice.price,
      }));

      setInvoicesData(invoice);

      let program = data.program.program;
      setProgramData(program);
    }
  }, [data]);

  const tabs = [
    { name: "Sales Team", href: "#", current: activeTab === "Sales Team" },
    { name: "Rosters", href: "#", current: activeTab === "Rosters" },
    { name: "Allowances", href: "#", current: activeTab === "Allowances" },
    { name: "Advances", href: "#", current: activeTab === "Advances" },
    { name: "Invoices", href: "#", current: activeTab === "Invoices" },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleTabClick = (e, tabName) => {
    e.preventDefault(); // Prevent default anchor link behavior
    setActiveTab(tabName);
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const formatNumber = (amount) => {
    // Customize the formatting options as needed (e.g., currency: 'USD')
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2, // Specify the minimum number of decimal places
    });
    return formatter.format(amount);
  };

  return (
    <Layout>
      <div className="pb-10 lg:pl-72">
        <Header program={programData} />
        <div className="sm:hidden mx-auto max-w-7xl pt-5 sm:pt-0 px-4 sm:px-6 lg:px-8">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            value={activeTab}
            onChange={(e) => handleTabChange(e.target.value)}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav
              className="-mb-px flex space-x-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 "
              aria-label="Tabs"
            >
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={(e) => handleTabClick(e, tab.name)}
                  className={classNames(
                    tab.current
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
        {/* Conditional rendering based on the activeTab */}
        <div className="mx-auto max-w-7xl py-10 px-4 sm:px-6 lg:px-8">
          {activeTab === "Sales Team" && (
            <div>
              <div className="flex flex-col gap-4 md:flex-row sm:justify-between">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">
                    Sale Team Information
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Detailed information on the sale team, including program
                    affiliations, sale types, involved personnel, and their
                    respective contributions.
                  </p>
                </div>
                <div className="relative group">
                  <Link
                    to={`/programs/${id}/sale-add`}
                    className="inline-block rounded-md bg-indigo-600 px-3 py-2 whitespace-nowrap text-center text-sm font-normal text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Add Team Member
                  </Link>
                </div>
              </div>

              <div className="mt-10">
                {salesData.length === 0 ? (
                  <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                    <img
                      src="/images/gif/wired-outline-56-document.gif"
                      alt="No Data"
                      className="mx-auto h-20 w-20"
                    />
                    <span className="mt-2 block text-sm font-semibold text-gray-500">
                      No sales team data available yet.
                    </span>
                    <p className="mt-2 text-sm text-gray-600">
                      Start adding sales team members to track your team's
                      performance and sales metrics.
                    </p>
                    <div className="mt-4">
                      <Link
                        to={`/programs/${id}/sale-add`}
                        className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Add First Team Member
                      </Link>
                    </div>
                  </div>
                ) : (
                  <table className="w-full text-left">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Sales Type
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Sales Percentage
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {salesData &&
                        salesData.map((sale) => (
                          <tr
                            key={sale.sales_type_id}
                            className="cursor-pointer hover:bg-gray-50"
                          >
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell whitespace-nowrap capitalize">
                              {sale.sales_type}
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell whitespace-nowrap">
                              {sale.personnel}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500">
                              {sale.sales_percentage}
                            </td>
                            <td>
                              <div className="flex gap-3">
                                <Link
                                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  to={`/programs/${id}/sale/${sale.id}`}
                                >
                                  <PencilSquareIcon className="h-5 w-5 text-white" />
                                </Link>

                                <button
                                  className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                  onClick={(e) => {
                                    const confirmed = window.confirm(
                                      "Are you sure you want to delete this row?"
                                    );
                                    if (confirmed) {
                                      delete_sale({
                                        variables: {
                                          id: sale.id,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  <TrashIcon className="h-5 w-5 text-white" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}

          {activeTab === "Rosters" && (
            <div>
              <div className="flex flex-col gap-4 md:flex-row sm:justify-between">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">
                    Roster Management
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    An overview of scheduled shifts, including detailed
                    information on program assignments, roles, personnel
                    involved, roster dates, rates, and specific duties or
                    remarks associated with each roster entry.
                  </p>
                </div>
                <div className="w-full md:w-auto">
                  <div className="relative group">
                    <Link
                      to={`/programs/${id}/roster-add`}
                      className="inline-block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 whitespace-nowrap"
                    >
                      Add Roster
                    </Link>
                    {/* Tooltip container with blue color for an informative look */}
                    <div className="absolute hidden w-48 px-2 py-1 text-xs text-white bg-blue-500 rounded-md shadow-lg group-hover:flex -left-full sm:left-auto sm:right-full sm:ml-0 sm:mr-3 top-1/2 -translate-y-1/2">
                      Do you want to add a new roster data?
                      {/* Tooltip arrow with matching blue color */}
                      <div className="absolute h-3 w-3 bg-blue-500 transform -rotate-45 -right-2 sm:left-auto sm:-right-1.5 top-1/2 -translate-y-1/2"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-10">
                {rostersData.length === 0 ? (
                  <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                    <img
                      src="/images/gif/wired-outline-56-document.gif"
                      alt="No Data"
                      className="mx-auto h-20 w-20"
                    />
                    <span className="mt-2 block text-sm font-semibold text-gray-500">
                      No roster data available yet.
                    </span>
                    <p className="mt-2 text-sm text-gray-600">
                      Start adding roster entries to track your team's schedules
                      and assignments.
                    </p>
                    <div className="mt-4">
                      <Link
                        to={`/programs/${id}/roster-add`}
                        className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Add First Entry
                      </Link>
                    </div>
                  </div>
                ) : (
                  <table className="w-full text-left">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 whitespace-nowrap"
                        >
                          Role
                          <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                          <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                        </th>

                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                          Roster Date
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                          Rate
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Remarks
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rostersData.map((roster) => (
                        <tr
                          key={roster.id}
                          className="cursor-pointer hover:bg-gray-50"
                        >
                          <td className="relative py-4 pr-3 text-sm font-medium text-gray-900 capitalize">
                            {roster.roleType}
                            <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                            <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell whitespace-nowrap">
                            {roster.personnel}
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell whitespace-nowrap">
                            {moment(roster.rosterDate).format("LL")}
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                            {formatNumber(roster.rate)}
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                            {roster.description}
                          </td>
                          <td className="px-3 py-4 text-sm text-gray-500">
                            {roster.remarks}
                          </td>
                          <td>
                            <div className="flex gap-3">
                              <Link
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                to={`/programs/${id}/roster/${roster.id}`}
                              >
                                <PencilSquareIcon className="h-5 w-5 text-white" />
                              </Link>

                              <button
                                className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                onClick={(e) => {
                                  const confirmed = window.confirm(
                                    "Are you sure you want to delete this row?"
                                  );
                                  if (confirmed) {
                                    delete_roster({
                                      variables: {
                                        id: roster.id,
                                      },
                                    });
                                  }
                                }}
                              >
                                <TrashIcon className="h-5 w-5 text-white" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}

          {activeTab === "Allowances" && (
            <div>
              <div className="flex flex-col gap-4 md:flex-row sm:justify-between">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">
                    Allowance Distribution
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    An overview of allocated allowances, including detailed
                    information on allowance types, recipients, distribution
                    dates, amounts, and specific notes or remarks associated
                    with each allowance entry.
                  </p>
                </div>
                <div className="w-full md:w-auto">
                  <div className="relative group w-full md:w-auto">
                    <Link
                      to={`/programs/${id}/allowance-add`}
                      className="inline-block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 whitespace-nowrap"
                    >
                      Add Allowance
                    </Link>
                    {/* Tooltip container with blue color for an informative look */}
                    <div className="absolute hidden w-48 px-2 py-1 text-xs text-white bg-blue-500 rounded-md shadow-lg group-hover:flex -left-full sm:left-auto sm:right-full sm:ml-0 sm:mr-3 top-1/2 -translate-y-1/2">
                      Do you want to edit this table allowances data?
                      {/* Tooltip arrow with matching blue color */}
                      <div className="absolute h-3 w-3 bg-blue-500 transform -rotate-45 -right-2 sm:left-auto sm:-right-1.5 top-1/2 -translate-y-1/2"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-10">
                {allowancesData.length === 0 ? (
                  <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                    <img
                      src="/images/gif/wired-outline-56-document.gif"
                      alt="No Data"
                      className="mx-auto h-20 w-20"
                    />
                    <span className="mt-2 block text-sm font-semibold text-gray-500">
                      No allowance data available yet.
                    </span>
                    <p className="mt-2 text-sm text-gray-600">
                      It seems like there are no allowance entries at the
                      moment. Add your first entry to begin tracking your team's
                      allowances.
                    </p>
                    <div className="mt-4">
                      <Link
                        to={`/programs/${id}/allowance-add`}
                        className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Add Allowance Entry
                      </Link>
                    </div>
                  </div>
                ) : (
                  <table className="w-full text-left">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 whitespace-nowrap"
                        >
                          Allowance Type
                          <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                          <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                        </th>

                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                          Receipt Received Date
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                          Amount
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allowancesData.map((allowance) => (
                        <tr
                          key={allowance.id}
                          className="cursor-pointer hover:bg-gray-50"
                        >
                          <td className="relative py-4 pr-3 text-sm font-medium text-gray-900 capitalize">
                            {allowance.allowanceType}
                            <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                            <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell whitespace-nowrap">
                            {allowance.personnel}
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell whitespace-nowrap">
                            {moment(allowance.receiptReceiveDate).format("LL")}
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                            {formatNumber(allowance.cost)}
                          </td>

                          <td>
                            <div className="flex gap-3">
                              <Link
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                to={`/programs/${id}/allowance/${allowance.id}`}
                              >
                                <PencilSquareIcon className="h-5 w-5 text-white" />
                              </Link>

                              <button
                                className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                onClick={(e) => {
                                  const confirmed = window.confirm(
                                    "Are you sure you want to delete this row?"
                                  );
                                  if (confirmed) {
                                    delete_allowance({
                                      variables: {
                                        id: allowance.id,
                                      },
                                    });
                                  }
                                }}
                              >
                                <TrashIcon className="h-5 w-5 text-white" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}

          {activeTab === "Advances" && (
            <div>
              <div className="flex flex-col gap-4 md:flex-row sm:justify-between">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">
                    Advance Payments
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A detailed record of advance payments, including recipient
                    details, payment dates, amounts, and any specific notes or
                    remarks associated with each advance payment.
                  </p>
                </div>
                <div className="w-full md:w-auto">
                  <div className="relative group w-full md:w-auto">
                    <Link
                      to={`/programs/${id}/advance-add`}
                      className="inline-block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 whitespace-nowrap"
                    >
                      Add Advance
                    </Link>
                    {/* Tooltip container with blue color for an informative look */}
                    <div className="absolute hidden w-48 px-2 py-1 text-xs text-white bg-blue-500 rounded-md shadow-lg group-hover:flex -left-full sm:left-auto sm:right-full sm:ml-0 sm:mr-3 top-1/2 -translate-y-1/2">
                      Do you want to edit this table advances data?
                      {/* Tooltip arrow with matching blue color */}
                      <div className="absolute h-3 w-3 bg-blue-500 transform -rotate-45 -right-2 sm:left-auto sm:-right-1.5 top-1/2 -translate-y-1/2"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-10">
                {advancesData.length === 0 ? (
                  <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                    <img
                      src="/images/gif/wired-outline-56-document.gif"
                      alt="No Data"
                      className="mx-auto h-20 w-20"
                    />
                    <span className="mt-2 block text-sm font-semibold text-gray-500">
                      No advance payments data available yet.
                    </span>
                    <p className="mt-2 text-sm text-gray-600">
                      Start adding advance payments to keep track of all
                      advances made, including recipient details, payment dates,
                      amounts, and any specific notes or remarks.
                    </p>
                    <div className="mt-4">
                      <Link
                        to={`/programs/${id}/advance-add`}
                        className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Add Advance Payment
                      </Link>
                    </div>
                  </div>
                ) : (
                  <table className="w-full text-left">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 whitespace-nowrap"
                        >
                          Cash Advance Type
                          <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                          <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                          Released Date
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {advancesData &&
                        advancesData.map((advance) => (
                          <tr
                            key={advance.id}
                            className="cursor-pointer hover:bg-gray-50"
                          >
                            <td className="relative py-4 pr-3 text-sm font-medium text-gray-900 capitalize">
                              {advance.advanceType}
                              <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                              <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                              {advance.personnel}
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                              {advance.cashAdvanceDate
                                ? moment(advance.cashAdvanceDate).format("LL")
                                : "Not Released"}
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                              {formatNumber(advance.cost)}
                            </td>
                            {!["released", "liquidated"].includes(
                              advance.status
                            ) && (
                              <td>
                                <div className="flex gap-3">
                                  <Link
                                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    to={`/programs/${id}/advance/${advance.id}`}
                                  >
                                    <PencilSquareIcon className="h-5 w-5 text-white" />
                                  </Link>

                                  <button
                                    className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                    onClick={(e) => {
                                      const confirmed = window.confirm(
                                        "Are you sure you want to delete this row?"
                                      );
                                      if (confirmed) {
                                        delete_advance({
                                          variables: {
                                            id: advance.id,
                                          },
                                        });
                                      }
                                    }}
                                  >
                                    <TrashIcon className="h-5 w-5 text-white" />
                                  </button>
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}

          {activeTab === "Invoices" && (
            <div>
              <div className="flex flex-col gap-4 md:flex-row sm:justify-between">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">
                    Invoices
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A detailed record of invoices, including billing details
                    such as 'Billed To', 'Invoice Date', and 'Price'. Each entry
                    provides a comprehensive overview of individual
                    transactions, tailored for clear and effective financial
                    tracking and management.
                  </p>
                </div>
                <div className="w-full md:w-auto">
                  <div className="relative group w-full md:w-auto">
                    <Link
                      to={`/programs/${id}/invoice-add`}
                      className="inline-block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 whitespace-nowrap"
                    >
                      Add Invoices
                    </Link>
                    {/* Tooltip container with blue color for an informative look */}
                    <div className="absolute hidden w-48 px-2 py-1 text-xs text-white bg-blue-500 rounded-md shadow-lg group-hover:flex -left-full sm:left-auto sm:right-full sm:ml-0 sm:mr-3 top-1/2 -translate-y-1/2">
                      Do you want to edit this table invoice data?
                      {/* Tooltip arrow with matching blue color */}
                      <div className="absolute h-3 w-3 bg-blue-500 transform -rotate-45 -right-2 sm:left-auto sm:-right-1.5 top-1/2 -translate-y-1/2"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-10">
                {invoicesData.length === 0 ? (
                  <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                    <img
                      src="/images/gif/wired-outline-56-document.gif"
                      alt="No Data"
                      className="mx-auto h-20 w-20"
                    />
                    <span className="mt-2 block text-sm font-semibold text-gray-500">
                      No invoice data available yet.
                    </span>
                    <p className="mt-2 text-sm text-gray-600">
                      Start adding invoices to keep track of all billing
                      transactions, including 'Billed To', 'Invoice Date', and
                      'Price', along with any specific notes or remarks for each
                      invoice.
                    </p>
                    <div className="mt-4">
                      <Link
                        to={`/programs/${id}/invoice-add`}
                        className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Add Invoice
                      </Link>
                    </div>
                  </div>
                ) : (
                  <table className="w-full text-left">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 whitespace-nowrap"
                        >
                          Invoice Date
                          <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                          <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                          Billed To
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoicesData &&
                        invoicesData.map((invoice) => (
                          <tr
                            key={invoice.id}
                            className="cursor-pointer hover:bg-gray-50"
                          >
                            <td className="relative py-4 pr-3 text-sm font-medium text-gray-900 capitalize">
                              {moment(invoice.invoiceDate).format("LL")}
                              <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                              <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                              {invoice.billedTo}
                            </td>

                            <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                              {formatNumber(invoice.price)}
                            </td>
                            <td>
                              <div className="flex gap-3">
                                <Link
                                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  to={`/programs/${id}/invoice/${invoice.id}`}
                                >
                                  <PencilSquareIcon className="h-5 w-5 text-white" />
                                </Link>

                                <button
                                  className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                  onClick={(e) => {
                                    const confirmed = window.confirm(
                                      "Are you sure you want to delete this row?"
                                    );
                                    if (confirmed) {
                                      delete_invoice({
                                        variables: {
                                          id: invoice.id,
                                        },
                                      });
                                    }
                                  }}
                                >
                                  <TrashIcon className="h-5 w-5 text-white" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
