import * as Yup from "yup";

import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import Select from "react-select";
import { useFormik } from "formik";

// Validation schema
const validationSchema = Yup.object({
  start_date: Yup.string().required("Required"),
  end_date: Yup.string().required("Required"),
});

export default function Filter(props) {
  const [statusOptions, setStausOptions] = useState([
    { label: "All", value: "all" },
    { label: "Ready to Invoice", value: "Ready to Invoice" },
    { label: "Request for GR sent", value: "Request for GR sent" },
    { label: "Invoice Sent", value: "Invoice Sent" },
    { label: "Follow-up 1", value: "Follow-up 1" },
    { label: "Follow-up 2", value: "Follow-up 2" },
    { label: "Follow-up 3", value: "Follow-up 3" },
    { label: "Escalated", value: "Escalated" },
    { label: "Payment Received", value: "Payment Received" },
  ]);

  const [status, setStatus] = useState({
    label: "All",
    value: "all",
  });

  const formik = useFormik({
    initialValues: {
      start_date: "",
      end_date: "",
      status: "all",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      console.log({
        variables: {
          start_date: values.start_date,
          end_date: values.end_date,
          status: values.status,
        },
      });
    },
  });

  useEffect(() => {
    let dates = formik.values;
    if (dates.start_date != "" || dates.end_date != "") {
      props.filter({
        page: 1,
        startDate: parseValue(dates.start_date),
        endDate: parseValue(dates.end_date),
        status: dates.status,
      });
      props.setPage(1);
    } else {
      props.filter({
        page: 1,
        status: dates.status,
      });
      props.setPage(1);
    }
  }, [formik.values.start_date, formik.values.end_date, formik.values.status]);

  const parseValue = (string) => {
    if (string == "") {
      return null;
    } else {
      return string;
    }
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-9 md:col-span-2 items-start">
            <div className="sm:col-span-3">
              <label
                htmlFor="start_date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Start date
              </label>

              <div className="mt-2 space-y-2">
                <input
                  autoFocus
                  id="start_date"
                  name="start_date"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.start_date}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {/* {formik.touched.start_date && formik.errors.start_date ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.start_date}
                  </div>
                ) : null} */}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="end_date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                End date
              </label>

              <div className="mt-2 space-y-2">
                <input
                  id="end_date"
                  name="end_date"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.end_date}
                  className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {/* {formik.touched.end_date && formik.errors.end_date ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.end_date}
                  </div>
                ) : null} */}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="end_date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Status
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={statusOptions}
                  name="status"
                  id="status"
                  instanceId="status"
                  value={{
                    label: status.label,
                    value: status.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("status", selected.value);
                    setStatus(selected);
                  }}
                  className="capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.status && formik.errors.status ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.status}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
