import * as Yup from "yup";

import React, { useEffect, useState } from "react";
import { gql, useApolloClient, useMutation } from "@apollo/client";

import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

const LOGIN_MUTATION = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      status
      token
    }
  }
`;

const GET_USER_INFO_QUERY = gql`
  query {
    getUserInfo {
      status
      user {
        id
        accessLevel
      }
    }
  }
`;

const validationSchema = Yup.object({
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

// Inside your component

const LoginPage = () => {
  const navigate = useNavigate();
  const client = useApolloClient();

  const [loginMutation] = useMutation(LOGIN_MUTATION, {
    onCompleted: async (data) => {
      if (data && data.login) {
        // Save the token
        localStorage.setItem("token", data.login.token);

        // Fetch user info to get accessLevel
        try {
          const { data: userInfoData } = await client.query({
            query: GET_USER_INFO_QUERY,
            fetchPolicy: "network-only", // Use this to make sure the query always goes to the network
          });

          if (userInfoData && userInfoData.getUserInfo) {
            // Save accessLevel to session storage
            sessionStorage.setItem(
              "accessLevel",
              userInfoData.getUserInfo.user.accessLevel
            );
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
        }

        navigate("/home");
      } else {
        alert("Invalid username/password");
      }
    },
    onError: (error) => {
      alert("Invalid username/password");
    },
  });

  // const [login_mutation] = useMutation(LOGIN_MUTATION, {
  //   onCompleted: (data) => {
  //     if (data && data.login) {
  //       localStorage.setItem("token", data.login.token);
  //       navigate("/home");
  //     } else {
  //       alert("Invalid username/password");
  //     }
  //   },
  //   onError: (error) => {
  //     alert("Invalid username/password");
  //   },
  // });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      loginMutation({
        variables: {
          email: values.email,
          password: values.password,
        },
      });
    },
  });

  return (
    <div className="relative h-screen overflow-hidden">
      <div className="flex flex-row h-screen">
        {/* Left Content */}
        <div className="hidden w-full lg:w-3/5 p-10 text-white relative md:flex md:flex-col md:justify-end h-screen">
          <img
            src="/images/png/data-analysis.png"
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover "
          />

          <div className="mt-10 pb-20">
            <h1 className="text-4xl font-bold mt-4">
              Effective Data Management
            </h1>
            <p className="mt-2">
              In the digital era, effective data management is crucial. It
              involves the development and execution of architectures, policies,
              practices and procedures in order to manage the information
              lifecycle needs of an enterprise in an effective manner. Good data
              management can help organizations to make decisions based on
              facts, identify new opportunities, and improve their operational
              efficiency. Because in data, we trust.
            </p>
          </div>
        </div>

        {/* Right Content */}
        <div className="w-full lg:w-2/5 bg-white p-10 relative">
          <div className="flex justify-between items-center">
            <button className="text-green-500 text-lg font-semibold">
              Register <i className="fas fa-arrow-right"></i>
            </button>
            <button className="text-green-500 text-lg">
              <ArrowRightIcon className="h-8 w-8" />
            </button>
          </div>
          <div className="flex flex-col justify-center items-center h-screen">
            <form onSubmit={formik.handleSubmit}>
              <div className="mx-10">
                <h2 className="text-3xl font-semibold text-gray-700 mb-2">
                  Login
                </h2>
                <p className="text-sm text-gray-600">
                  Welcome! Please fill username and password to sign in into
                  your account
                </p>
                <input
                  id="email"
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder="Type your email / username"
                  className="block rounded-full w-full border-2 border-green-500 mt-4 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-green-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset active:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6"
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.email}
                  </div>
                ) : null}

                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  placeholder="Type your password"
                  className="block rounded-full w-full border-2 border-green-500 mt-10 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-green-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset active:ring-green-600 focus:ring-green-600 sm:text-sm sm:leading-6"
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.password}
                  </div>
                ) : null}
                <a
                  href="/"
                  className="text-xs text-green-500 hover:underline text-right block mt-3"
                >
                  Forgot the password?
                </a>
                <button
                  type="submit"
                  className="bg-green-500 text-white rounded-full px-8 py-3 mt-6 w-full"
                >
                  Login
                </button>

                <br />
                {/* <p className="text-sm text-red-600 mt-6">
                  {JSON.stringify(error)}
                </p> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
