import { DndProvider, useDrag, useDrop } from "react-dnd";
import React, { useCallback, useRef, useState } from "react";

import { HTML5Backend } from "react-dnd-html5-backend";
import { v4 as uuidv4 } from "uuid";

const EditableCell = ({
  value,
  rowIndex,
  cellIndex,
  updateData,
  totalCells,
  cellConfig,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [cellValue, setCellValue] = useState(value);
  const { type, options } = cellConfig[cellIndex] || {};

  const handleBlur = () => {
    setIsEditing(false);
    updateData(rowIndex, cellIndex, cellValue);
  };

  const handleChange = (e) => {
    setCellValue(e.target.value);
  };

  const tdClass =
    cellIndex === 0
      ? "relative isolate py-3.5 pr-3 text-left text-sm font-normal text-gray-900"
      : cellIndex === totalCells - 1
      ? "px-3 py-3.5 text-left text-sm font-normal text-gray-900"
      : "hidden px-3 py-3.5 text-left text-sm font-normal text-gray-900 md:table-cell";

  return (
    <td className={tdClass} onDoubleClick={() => setIsEditing(true)}>
      {isEditing ? (
        type === "select" ? (
          <select
            autoFocus
            className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={cellValue}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            type="text"
            autoFocus
            className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={cellValue}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        )
      ) : (
        <span>{cellValue}</span>
      )}
    </td>
  );
};

const RowItem = ({
  id,
  data,
  index,
  moveRow,
  updateData,
  deleteRow,
  totalCells,
  cellConfig,
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "row",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "row",
    item: () => ({ id, index }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  drag(drop(ref));

  return (
    <tr
      ref={ref}
      style={{ opacity: isDragging ? 0 : 1 }}
      className="cursor-move"
    >
      {Object.values(data).map((cell, cellIndex) => (
        <EditableCell
          key={uuidv4()}
          value={cell}
          rowIndex={index}
          cellIndex={cellIndex}
          updateData={updateData}
          totalCells={totalCells}
          cellConfig={cellConfig}
        />
      ))}
      <td className="px-3 py-3.5 text-right text-sm font-normal text-gray-900">
        <button
          onClick={() => deleteRow(index)}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

const DraggableTable = ({ initialData, onDataUpdate, headers, cellConfig }) => {
  const [data, setData] = useState(initialData);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRecord = data[dragIndex];
      const newData = [...data];
      newData.splice(dragIndex, 1);
      newData.splice(hoverIndex, 0, dragRecord);
      setData(newData);
    },
    [data]
  );

  const updateData = useCallback(
    (rowIndex, cellIndex, value) => {
      const newData = [...data];
      const keyToUpdate = Object.keys(newData[rowIndex])[cellIndex];
      newData[rowIndex][keyToUpdate] = value;
      setData(newData);
    },
    [data]
  );

  const deleteRow = useCallback(
    (index) => {
      const newData = [...data];
      newData.splice(index, 1);
      setData(newData);
      onDataUpdate(newData);
    },
    [data, onDataUpdate]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <table className="w-full text-left">
        <thead className="bg-white">
          <tr>
            {headers.map((header, index) => (
              <th
                key={uuidv4()}
                scope="col"
                className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
              >
                {header}
              </th>
            ))}
            <th
              scope="col"
              className="relative px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <RowItem
              key={uuidv4()}
              id={uuidv4()}
              data={row}
              index={index}
              moveRow={moveRow}
              updateData={updateData}
              deleteRow={deleteRow}
              totalCells={headers.length}
              cellConfig={cellConfig}
            />
          ))}
        </tbody>
      </table>
    </DndProvider>
  );
};

export default DraggableTable;
