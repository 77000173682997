import * as Yup from "yup";

import { Layout, ModalAlert } from "@components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useRef, useState } from "react";

import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Header from "../../../components/Header";
import Select from "react-select";
import { useEffect } from "react";
import { useFormik } from "formik";

// Validation schema
const validationSchema = Yup.object({
  program_id: Yup.string().required("Required"),
  sales_type_id: Yup.string().required("Required"),
  personnel_id: Yup.string().required("Required").nullable(),
  sales_percentage: Yup.number()
    .required("Required")
    .typeError("Must be a number")
    .max(100, "Value must not be greater than 100"),
});

const SELECT_FIELD_QUERY = gql`
  query ($id: String!, $salesId: String!) {
    program(id: $id) {
      program {
        id
        clientName
        clientDepartment
        clientContactName
        clientContactDetails
        programVenue
        programPax
        programTypeIndoorModular
        programTypeTeambuilding
        programTypeConsultation
        programTypeIndoorConvention
        programEmtCost
        programToolName
        programToolCost
        programToolPrice
        programToolQuantity
        programOverhead
        programToolContractPrice
        totalInvoicePrice
        isInvoiceEqualToContractPrice
        totalContractPrice
      }
    }
    sale(id: $salesId) {
      sale {
        id
        personnel {
          id
          firstName
          lastName
        }
        saleType {
          id
          referenceCode
          referenceType
          referenceDescription
        }
        salesPercentage
      }
    }
    references {
      status
      references {
        id
        referenceCode
        referenceType
        referenceDescription
      }
    }
    personnels {
      status
      personnels {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

const UPDATE_SALE_MUTATION = gql`
  mutation (
    $id: String!
    $personnelId: String
    $programId: String
    $saleTypeId: String
    $salesPercentage: Float
  ) {
    updateSale(
      id: $id
      personnelId: $personnelId
      programId: $programId
      saleTypeId: $saleTypeId
      salesPercentage: $salesPercentage
    ) {
      sale {
        id
        salesPercentage
      }
    }
  }
`;

export default function SaleEdit() {
  const [programData, setProgramData] = useState();
  const [salesTypeId, setSalesTypeId] = useState("");
  const [personnelId, setPersonnelId] = useState("");
  const [activeTab, setActiveTab] = useState("Sales Team");
  const [showModal, setShowModal] = useState(false);
  const [salesTypeIdOptions, setSalesTypeOptions] = useState([]);
  const [personnelIdOptions, setPersonnelOptions] = useState([]);
  const { id, salesId } = useParams();
  const { data } = useQuery(SELECT_FIELD_QUERY, {
    variables: { id: id, salesId: salesId },
  });

  const [update_sale] = useMutation(UPDATE_SALE_MUTATION, {
    onCompleted: (data) => {
      if (data && data.updateSale) {
        // window.location.replace(
        //   `/programs/${id}/sale-edit-mode?message=Create successfully`
        // );
        window.location.replace(`/programs/${id}?module=Sales Team`);
      } else {
        alert("Something went wrong");
      }
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });

  useEffect(() => {
    if (data) {
      let program = data.program.program;
      setProgramData(program);

      let references =
        data &&
        data?.references.references
          .filter((reference) => reference.referenceType === "sales")
          .map((reference) => ({
            value: reference.id,
            label: reference.referenceDescription,
          }));

      let personnels =
        data &&
        data?.personnels.personnels.map((personnel) => ({
          value: personnel.id,
          label: `${personnel.firstName} ${personnel.lastName}`,
        }));

      // Add "N/A" option to the personnels array
      personnels = [{ value: null, label: "N/A" }, ...personnels];

      let salesType = data?.sale.sale.saleType;
      let personnel = data?.sale.sale.personnel;

      formik.setFieldValue("personnel_id", personnel?.id || null);
      formik.setFieldValue("sales_type_id", salesType.id);
      formik.setFieldValue("sales_percentage", data?.sale.sale.salesPercentage);

      setPersonnelId({
        value: personnel?.id || null,
        label: personnel
          ? `${personnel.firstName} ${personnel.lastName}`
          : "N/A",
      });

      setSalesTypeId({
        value: salesType.id,
        label: salesType.referenceDescription,
      });

      setSalesTypeOptions(references || []);
      setPersonnelOptions(personnels || []);
    }
  }, [data]);

  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const tabs = [
    {
      name: "Sales Team",
      href: `/programs/${id}`,
      current: activeTab === "Sales Team",
    },
    {
      name: "Rosters",
      href: `/programs/${id}`,
      current: activeTab === "Rosters",
    },
    {
      name: "Allowances",
      href: `/programs/${id}`,
      current: activeTab === "Allowances",
    },
    {
      name: "Advances",
      href: `/programs/${id}`,
      current: activeTab === "Advances",
    },
    {
      name: "Invoices",
      href: `/programs/${id}`,
      current: activeTab === "Invoices",
    },
  ];

  const handleLeaveClick = (e, tabName) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleLeave = (decision) => {
    setShowModal(false);
    if (decision === "yes") {
      navigate(`/programs/${id}`);
      setShowModal(false);
    } else {
      setShowModal(false);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const formik = useFormik({
    initialValues: {
      program_id: id,
      sales_type_id: "",
      personnel_id: null,
      sales_percentage: "",
    },
    validationSchema,
    onSubmit: (values) => {
      update_sale({
        variables: {
          id: salesId,
          programId: values.program_id,
          saleTypeId: values.sales_type_id,
          personnelId: values.personnel_id,
          salesPercentage: parseFloat(values.sales_percentage),
        },
      });
    },
  });

  return (
    <Layout>
      <div className="pb-10 lg:pl-72">
        <Header program={programData} />
        <div className="sm:hidden mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            value={activeTab}
            onChange={(e) => handleLeaveClick(e.target.value)}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav
              className="-mb-px flex space-x-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 "
              aria-label="Tabs"
            >
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={(e) => handleLeaveClick(e, tab.name)}
                  className={classNames(
                    tab.current
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>

        {/* Conditional rendering based on the activeTab */}
        <div className="mx-auto max-w-7xl py-10 px-4 sm:px-6 lg:px-8">
          {activeTab === "Sales Team" && (
            <div>
              <form onSubmit={formik.handleSubmit}>
                <div className="space-y-12">
                  <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Sales Record Entry
                      </h2>
                      <p className="mt-1 text-sm leading-6 text-gray-600">
                        Please provide the sales details below.
                      </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                      <div className=" col-span-full">
                        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                          <div className="sm:col-span-3">
                            <label
                              htmlFor="sales_type_id"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Sales Type <span className="text-red-600">*</span>
                            </label>

                            <div className="mt-2 space-y-2">
                              <Select
                                autoFocus
                                options={salesTypeIdOptions}
                                name="sales_type_id"
                                id="sales_type_id"
                                instanceId="sales_type_id"
                                value={{
                                  label: salesTypeId.label,
                                  value: salesTypeId.value,
                                }}
                                onChange={(selected) => {
                                  formik.setFieldValue(
                                    "sales_type_id",
                                    selected.value
                                  );
                                  setSalesTypeId(selected);
                                }}
                                className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />

                              {formik.touched.sales_type_id &&
                              formik.errors.sales_type_id ? (
                                <div className="text-red-600 text-sm">
                                  {formik.errors.sales_type_id}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="sm:col-span-3">
                            <label
                              htmlFor="sales_type_id"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Name <span className="text-red-600">*</span>
                            </label>

                            <div className="mt-2 space-y-2">
                              <Select
                                options={personnelIdOptions}
                                name="personnel_id"
                                id="personnel_id"
                                instanceId="personnel_id"
                                value={{
                                  label: personnelId.label,
                                  value: personnelId.value,
                                }}
                                onChange={(selected) => {
                                  formik.setFieldValue(
                                    "personnel_id",
                                    selected.value
                                  );
                                  setPersonnelId(selected);
                                }}
                                className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />

                              {formik.touched.personnel_id &&
                              formik.errors.personnel_id ? (
                                <div className="text-red-600 text-sm">
                                  {formik.errors.personnel_id}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="sales_percentage"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Sales Percentage
                        </label>

                        <div className="mt-2 space-y-2">
                          <input
                            id="sales_percentage"
                            name="sales_percentage"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.sales_percentage}
                            className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {formik.touched.sales_percentage &&
                          formik.errors.sales_percentage ? (
                            <div className="text-red-600 text-sm">
                              {formik.errors.sales_percentage}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <Link
                    to={`/programs/${id}?module=Sales Team`}
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    Cancel
                  </Link>
                  <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <>
          <ModalAlert open={showModal} setOpen={setShowModal}>
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  Leave Edit Mode
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Do you want to exit edit mode? Your unsaved changes might be
                    lost.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                onClick={() => handleLeave("yes")}
              >
                Yes
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => handleLeave("no")}
                ref={cancelButtonRef}
              >
                No
              </button>
            </div>
          </ModalAlert>
        </>
      )}
    </Layout>
  );
}
