// utils/formatNumber.js
const formatNumber = (amount) => {
  // Customize the formatting options as needed (e.g., currency: 'USD')
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2, // Specify the minimum number of decimal places
  });
  return formatter.format(amount);
};

export default formatNumber;
