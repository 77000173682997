import * as Yup from "yup";

import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import Select from "react-select";
import { useFormik } from "formik";

const FILTER_QUERY = gql`
  query ($program: String, $personnel: String, $status: String) {
    advanceFilters(program: $program, personnel: $personnel, status: $status) {
      status
      programs {
        id
        clientName
      }
      personnels {
        id
        firstName
        lastName
      }
      statuses
    }
  }
`;
// Validation schema
const validationSchema = Yup.object({
  client_name: Yup.string().required("Required"),
  personnel_id: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

export default function Filter(props) {
  const { loading, error, data, refetch } = useQuery(FILTER_QUERY, {
    variables: { program: "all", personnel: "all", status: "all" },
  });
  const [clientName, setClientName] = useState({ label: "All", value: "all" });
  const [personnelId, setPersonnelId] = useState({
    label: "All",
    value: "all",
  });
  const [status, setStatus] = useState({
    label: "All",
    value: "all",
  });
  const [clientNameOptions, setClientNameOptions] = useState([
    {
      label: "client 1",
      value: "client 1",
    },
    {
      label: "client 2",
      value: "client 3",
    },
  ]);
  const [personnelIdOptions, setPersonnelOptions] = useState([
    {
      label: "personnel  1",
      value: "personnel 1",
    },
    {
      label: "personnel 2",
      value: "personnel 3",
    },
  ]);
  const [statusOptions, setStatusOptions] = useState([
    {
      label: "All",
      value: "all",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Released",
      value: "released",
    },
    {
      label: "Liquidated",
      value: "liquidated",
    },
  ]);

  useEffect(() => {
    // Update formik values when data is available
    if (data && data.advanceFilters) {
      let programs = data.advanceFilters.programs.map((program) => ({
        label: program.clientName,
        value: program.id,
      }));
      setClientNameOptions([...[{ label: "All", value: "all" }], ...programs]);
      let personnels = data.advanceFilters.personnels.map((personnel) => ({
        value: personnel.id,
        label: `${personnel.firstName} ${personnel.lastName}`,
      }));
      setPersonnelOptions([...[{ label: "All", value: "all" }], ...personnels]);
    }
  }, [data]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const formik = useFormik({
    initialValues: {
      client_name: "",
      personnel_id: "",
      status: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log({
        variables: {
          client_name: values.client_name,
          personnel_id: values.personnel_id,
          status: values.status,
        },
      });
    },
  });

  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-10 md:col-span-2 items-end">
            <div className="sm:col-span-3">
              <label
                htmlFor="client_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Client name
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  autoFocus
                  options={clientNameOptions}
                  name="client_name"
                  id="client_name"
                  instanceId="client_name"
                  value={{
                    label: clientName.label,
                    value: clientName.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("client_name", selected.value);
                    refetch({
                      program: selected.value,
                      personnel: "all",
                    });
                    setClientName(selected);
                    setPersonnelId({ label: "All", value: "all" });
                    setStatus({ label: "All", value: "all" });
                    if (selected.value != "all") {
                      props.setQueryFilters({
                        page: 1,
                        programId: selected.value,
                      });
                    } else {
                      props.setQueryFilters({
                        page: 1,
                        programId: null,
                      });
                    }
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.client_name && formik.errors.client_name ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.client_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="personnel_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Name <span className="text-red-600">*</span>
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={personnelIdOptions}
                  name="personnel_id"
                  id="personnel_id"
                  instanceId="personnel_id"
                  value={{
                    label: personnelId.label,
                    value: personnelId.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("personnel_id", selected.value);
                    setPersonnelId(selected);
                    setStatus({ label: "All", value: "all" });
                    if (selected.value != "all") {
                      props.setQueryFilters({
                        page: 1,
                        status: null,
                        programId:
                          clientName.value == "all" ? null : clientName.value,
                        personnelId: selected.value,
                      });
                    } else {
                      props.setQueryFilters({
                        page: 1,
                        status: null,
                        programId:
                          clientName.value == "all" ? null : clientName.value,
                        personnelId: null,
                      });
                    }
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.personnel_id && formik.errors.personnel_id ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.personnel_id}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="status"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Status <span className="text-red-600">*</span>
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={statusOptions}
                  name="status"
                  id="status"
                  instanceId="status"
                  value={{
                    label: status.label,
                    value: status.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("status", selected.value);
                    setStatus(selected);
                    if (selected.value != "all") {
                      props.setQueryFilters({
                        page: 1,
                        programId:
                          clientName.value == "all" ? null : clientName.value,
                        personnelId:
                          personnelId.value == "all" ? null : personnelId.value,
                        status: selected.value,
                      });
                    } else {
                      props.setQueryFilters({
                        page: 1,
                        programId:
                          clientName.value == "all" ? null : clientName.value,
                        personnelId:
                          personnelId.value == "all" ? null : personnelId.value,
                        status: null,
                      });
                    }
                  }}
                  className="block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {formik.touched.status && formik.errors.status ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.status}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
