import React, { useState } from "react";

import { DndProvider } from "react-dnd";
import DraggableTable from "./DraggableTable";
import { HTML5Backend } from "react-dnd-html5-backend";

function Table() {
  const [initialData] = useState([
    ["Asta", "asta@example.com"],
    ["Gray", "gray@example.com"],
  ]);

  const handleDataUpdate = (newData) => {
    // Additional actions upon data update can be added here
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="container mx-auto p-4">
        <h1 className="text-xl font-semibold mb-4">
          Editable and Draggable Table
        </h1>
        <DraggableTable
          initialData={initialData}
          onDataUpdate={handleDataUpdate}
        />
      </div>
    </DndProvider>
  );
}

export default Table;
