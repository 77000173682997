import { DraggableTable, Layout, ModalAlert } from "@components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRef, useState } from "react";

import { DndProvider } from "react-dnd";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { HTML5Backend } from "react-dnd-html5-backend";

export default function AdvanceEditMode() {
  const [activeTab, setActiveTab] = useState("Advances");
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const tabs = [
    {
      name: "Sales Team",
      href: `/programs/${id}`,
      current: activeTab === "Sales Team",
    },
    {
      name: "Rosters",
      href: `/programs/${id}`,
      current: activeTab === "Rosters",
    },
    {
      name: "Allowances",
      href: `/programs/${id}`,
      current: activeTab === "Allowances",
    },
    {
      name: "Advances",
      href: `/programs/${id}`,
      current: activeTab === "Advances",
    },
    {
      name: "Invoices",
      href: `/programs/${id}`,
      current: activeTab === "Invoices",
    },
  ];

  const handleLeaveClick = (e, tabName) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleLeave = (decision) => {
    setShowModal(false);
    if (decision === "yes") {
      navigate(`/programs/${id}`);
      setShowModal(false);
    } else {
      setShowModal(false);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [initialData] = useState([
    {
      program_id: "P101",
      allowance_type_id: "AT001",
      personnel_id: "PE001",
      receipt_received_date: "2024-01-28T08:00:00.000Z",
      cost: 150.0,
    },
    {
      program_id: "P102",
      allowance_type_id: "AT002",
      personnel_id: "PE002",
      receipt_received_date: "2024-01-29T08:00:00.000Z",
      cost: 160.0,
    },
    {
      program_id: "P103",
      allowance_type_id: "AT003",
      personnel_id: "PE003",
      receipt_received_date: "2024-01-30T08:00:00.000Z",
      cost: 170.0,
    },
    {
      program_id: "P104",
      allowance_type_id: "AT004",
      personnel_id: "PE004",
      receipt_received_date: "2024-01-31T08:00:00.000Z",
      cost: 180.0,
    },
    {
      program_id: "P105",
      allowance_type_id: "AT005",
      personnel_id: "PE005",
      receipt_received_date: "2024-02-01T08:00:00.000Z",
      cost: 190.0,
    },
    {
      program_id: "P106",
      allowance_type_id: "AT006",
      personnel_id: "PE006",
      receipt_received_date: "2024-02-02T08:00:00.000Z",
      cost: 200.0,
    },
    {
      program_id: "P107",
      allowance_type_id: "AT007",
      personnel_id: "PE007",
      receipt_received_date: "2024-02-03T08:00:00.000Z",
      cost: 210.0,
    },
    {
      program_id: "P108",
      allowance_type_id: "AT008",
      personnel_id: "PE008",
      receipt_received_date: "2024-02-04T08:00:00.000Z",
      cost: 220.0,
    },
    {
      program_id: "P109",
      allowance_type_id: "AT009",
      personnel_id: "PE009",
      receipt_received_date: "2024-02-05T08:00:00.000Z",
      cost: 230.0,
    },
    {
      program_id: "P110",
      allowance_type_id: "AT010",
      personnel_id: "PE010",
      receipt_received_date: "2024-02-06T08:00:00.000Z",
      cost: 240.0,
    },
  ]);

  const handleDataUpdate = (newData) => {
    // Additional actions upon data update can be added here
  };

  const cellConfig = [
    { type: "text" }, // ID column configuration
    // Name column configuration
    {
      type: "select",
      options: [
        { value: 10, label: "10" },
        { value: 20, label: "20" },
      ],
    }, // Quantity column with select options
    { type: "text" },
    { type: "text" },
    { type: "text" },
  ];

  return (
    <Layout>
      <div className="pb-10 lg:pl-72">
        <div className="border-b border-gray-200 py-3">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
            <h1 className="text-xl font-semibold text-gray-700">John Doe</h1>
            <p className="text-sm font-normal text-gray-500 mt-1">
              Preferred client with frequent transactions
            </p>
          </div>
        </div>
        <div className="sm:hidden mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            value={activeTab}
            onChange={(e) => handleLeaveClick(e.target.value)}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav
              className="-mb-px flex space-x-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 "
              aria-label="Tabs"
            >
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={(e) => handleLeaveClick(e, tab.name)}
                  className={classNames(
                    tab.current
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>

        {/* Conditional rendering based on the activeTab */}
        <div className="mx-auto max-w-7xl py-10 px-4 sm:px-6 lg:px-8">
          {activeTab === "Advances" && (
            <div>
              <div className="flex flex-col gap-4 md:flex-row sm:justify-between">
                <div className="sm:flex-auto">
                  <div className="flex flex-row items-center space-x-3">
                    <h1 className="text-base font-semibold leading-6 text-gray-900 space-x-3">
                      Advance Payments
                    </h1>
                    <span className="inline-flex items-center rounded-full bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">
                      Edit Mode
                    </span>
                  </div>

                  <p className="mt-2 text-sm text-gray-700">
                    A detailed record of advance payments, including recipient
                    details, payment dates, amounts, and any specific notes or
                    remarks associated with each advance payment.
                  </p>
                </div>

                <div className="flex flex-row items-center gap-3">
                  <div className="relative group">
                    <Link
                      to={`/programs/${id}/advance-add`}
                      className="inline-block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Add
                    </Link>
                    {/* Tooltip container with blue color for an informative look */}
                    <div className="absolute hidden w-48 px-2 py-1 text-xs text-white bg-blue-500 rounded-md shadow-lg group-hover:flex -left-full sm:left-auto sm:right-full sm:ml-0 sm:mr-3 top-1/2 -translate-y-1/2">
                      Do you want to add a new advances data?
                      {/* Tooltip arrow with matching blue color */}
                      <div className="absolute h-3 w-3 bg-blue-500 transform -rotate-45 -right-2 sm:left-auto sm:-right-1.5 top-1/2 -translate-y-1/2"></div>
                    </div>
                  </div>

                  <div className="relative group">
                    <button
                      onClick={handleLeaveClick}
                      className="inline-block rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                    >
                      Leave
                    </button>
                    {/* Tooltip container with blue color for an informative look */}
                    <div className="absolute hidden w-48 px-2 py-1 text-xs text-white bg-blue-500 rounded-md shadow-lg group-hover:flex -left-full sm:left-auto sm:right-full sm:ml-0 sm:mr-3 top-1/2 -translate-y-1/2">
                      Do you want to exit the table's edit mode?
                      {/* Tooltip arrow with matching blue color */}
                      <div className="absolute h-3 w-3 bg-blue-500 transform -rotate-45 -right-2 sm:left-auto sm:-right-1.5 top-1/2 -translate-y-1/2"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-10">
                <DndProvider backend={HTML5Backend}>
                  <DraggableTable
                    initialData={initialData}
                    onDataUpdate={handleDataUpdate}
                    headers={[
                      "Program ID",
                      "Advance Type ID",
                      "Personnel ID",
                      "Cash Advance Date",
                      "Cost",
                    ]}
                    cellConfig={cellConfig}
                  />
                </DndProvider>
              </div>
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <>
          <ModalAlert open={showModal} setOpen={setShowModal}>
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  Leave Edit Mode
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Do you want to exit edit mode? Your unsaved changes might be
                    lost.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                onClick={() => handleLeave("yes")}
              >
                Yes
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => handleLeave("no")}
                ref={cancelButtonRef}
              >
                No
              </button>
            </div>
          </ModalAlert>
        </>
      )}
    </Layout>
  );
}
