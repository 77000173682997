const Loader = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <div className="flex-shrink-0">
        <img
          src="/images/gif/wired-outline-12-layers.gif"
          alt="loading"
          className="mx-auto h-auto max-h-28 w-28"
        />
      </div>
      <p className="text-gray-500">Loading...</p>
    </div>
  );
};

export default Loader;
