import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

import { v4 as uuidv4 } from "uuid";

const RowItem = ({ id, data, index, moveRow, updateData }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "row",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "row",
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <tr
      ref={ref}
      className={`${isDragging ? "bg-gray-100" : "bg-white"} cursor-move`}
    >
      {data.map((cell, cellIndex) => (
        <EditableCell
          key={uuidv4()}
          value={cell}
          rowIndex={index}
          cellIndex={cellIndex}
          updateData={updateData}
        />
      ))}
    </tr>
  );
};

const EditableCell = ({ value, rowIndex, cellIndex, updateData }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [cellValue, setCellValue] = useState(value);

  const handleBlur = () => {
    setIsEditing(false);
    updateData(rowIndex, cellIndex, cellValue);
  };

  return (
    <td className="border px-4 py-2" onDoubleClick={() => setIsEditing(true)}>
      {isEditing ? (
        <input
          type="text"
          autoFocus
          className="w-full"
          value={cellValue}
          onChange={(e) => setCellValue(e.target.value)}
          onBlur={handleBlur}
        />
      ) : (
        <span>{cellValue}</span>
      )}
    </td>
  );
};

const DraggableTable = ({ initialData, onDataUpdate }) => {
  const [data, setData] = useState(initialData);

  useEffect(() => {
    onDataUpdate(data); // Call the callback whenever the data changes
  }, [data, onDataUpdate]);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRecord = data[dragIndex];
      const newData = [...data];
      newData.splice(dragIndex, 1);
      newData.splice(hoverIndex, 0, dragRecord);
      setData(newData);
    },
    [data]
  );

  const updateData = (rowIndex, cellIndex, value) => {
    const newData = [...data];
    newData[rowIndex][cellIndex] = value;
    setData(newData);
  };

  return (
    <table className="min-w-full">
      <thead>
        <tr>
          <th className="border px-4 py-2">Name</th>
          <th className="border px-4 py-2">Email</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <RowItem
            key={uuidv4()}
            id={uuidv4()}
            data={row}
            index={index}
            moveRow={moveRow}
            updateData={updateData}
          />
        ))}
      </tbody>
    </table>
  );
};

export default DraggableTable;
