import * as Yup from "yup";

import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import Select from "react-select";
import { useFormik } from "formik";

// Validation schema
const validationSchema = Yup.object({
  start_date: Yup.string().required("Required"),
  end_date: Yup.string().required("Required"),
});

export default function Filter(props) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [statusOptions, setStausOptions] = useState([
    { label: "ALL", value: "ALL" },
    { label: "OPEN", value: "OPEN" },
    { label: "CLOSED", value: "CLOSED" },
  ]);

  const [status, setStatus] = useState({
    label: "ALL",
    value: "ALL",
  });

  const formik = useFormik({
    initialValues: {
      status: "ALL",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      console.log({
        variables: {
          status: values.status,
        },
      });
    },
  });

  useEffect(() => {
    let dates = formik.values;
    if (dates.start_date != "" && dates.end_date != "") {
      props.filter({
        page: 1,
        status: dates.status,
      });
      props.setPage(1);
    } else {
      props.filter({
        page: 1,
        status: dates.status,
      });
      props.setPage(1);
    }
  }, [formik.values.start_date, formik.values.end_date, formik.values.status]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-9 md:col-span-2 items-start">
            <div className="sm:col-span-3">
              <label
                htmlFor="end_date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Status
              </label>

              <div className="mt-2 space-y-2">
                <Select
                  options={statusOptions}
                  name="status"
                  id="status"
                  instanceId="status"
                  value={{
                    label: status.label,
                    value: status.value,
                  }}
                  onChange={(selected) => {
                    formik.setFieldValue("status", selected.value);
                    setStatus(selected);
                  }}
                  className="capitalize block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                {/* {formik.touched.status && formik.errors.status ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.status}
                  </div>
                ) : null} */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
